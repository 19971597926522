import React, { useState } from 'react';
// import logo from '../../assets/images/logo.png';
import burgerMenu from '../../assets/images/menu-burger.svg';
import closeIcon from '../../assets/images/close-icon.svg';
import './NavBarMobile.scss';

function NavBarMobile() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      const sectionTop = section.offsetTop - 6 * 16; // 6rem en pixels (supposant une taille de police de base de 16px)

      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth', // Pour une animation fluide
      });

      setMenuOpen(false); // Fermer le menu après la navigation
    }
  };

  return (
    <nav className="navbar-mobile">
      {/* <img className="logo" src={logo} alt="logo" /> */}
      <p className="logo"><span className='logo__happy'>Happy</span>VitaCode</p>
      <div className="menu-burger" onClick={toggleMenu}>
        {menuOpen ? (
          <img src={closeIcon} alt="closeIcon" className='menu-burger' />
        ) : (
          <img src={burgerMenu} alt="burgerMenu" className='menu-burger' />
        )}
      </div>
      {menuOpen && (
        <div className="mobile-navigation">
          <div className="modal-content">
            <ul className="menu-dropdown">
              <li>
                <a href='#acceuil' onClick={(e) => { e.preventDefault(); handleLinkClick('accueil'); }}>Accueil</a>
              </li>
              <li>
                <a href='#formations-experiences' onClick={(e) => { e.preventDefault(); handleLinkClick('formations-experiences'); }}>Formations et expériences</a>
              </li>
              <li>
                <a href='#technologies' onClick={(e) => { e.preventDefault(); handleLinkClick('technologies'); }}>Technologies</a>
              </li>
              <li>
                <a href='#projects' onClick={(e) => { e.preventDefault(); handleLinkClick('projects'); }}>Projets</a>
              </li>
              <li>
                <a href='#about-me' onClick={(e) => { e.preventDefault(); handleLinkClick('about-me'); }}>A propos de moi</a>
              </li>
              <li>
                <a href='#contact' onClick={(e) => { e.preventDefault(); handleLinkClick('contact'); }}>Contact</a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
}

export default NavBarMobile;

