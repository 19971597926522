import './Home.scss';
import NavBarMobile from '../../components/navbar/NavBarMobile';
import NavBarDesktop from '../../components/navbar/NavBarDesktop';
import Accueil from '../../components/sections/Accueil';  
import SectionEducationAndCareer from '../../components/sections/SectionEducationAndCareer';
import SectionTechnologies from '../../components/sections/SectionTechnologies';
import SectionProjets from '../../components/sections/SectionProjets';
import AboutMe from '../../components/sections/AboutMe';
import Contact from '../../components/footer/Contact';

function Home() {
  return (
    <div className="home-container">
      <header className="header">
        <NavBarMobile />
        <NavBarDesktop />
        <Accueil />
      </header>

      <main>
        <SectionEducationAndCareer />
        <SectionTechnologies />
        <SectionProjets />
        <AboutMe />
      </main>

      <footer>
        <Contact />
      </footer>
    </div>
  );
}

export default Home;

