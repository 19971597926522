import "./Accueil.scss"
import imgHome from "../../assets/images/imgAccueil.png"

import React, { useEffect, useRef } from "react";
import Typed from "typed.js";

function Accueil() {
    const typeTarget = useRef(null);

    useEffect(() => {
        const options = {
            strings: ["Bienvenue dans mon portfolio !", "Je suis Valentin Ribeiro, développeur web.", "Bonne visite !"],
            typeSpeed: 50,  // Vitesse de dactylographie en millisecondes
            backSpeed: 25,  // Vitesse de suppression en millisecondes
            backDelay: 1000,  // Délai avant de commencer à supprimer le texte
            startDelay: 500,  // Délai avant que la frappe ne commence
            loop: true,      // Boucle l'animation indéfiniment
        };

        const typed = new Typed(typeTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <section id='accueil' className="intro-section">
            <div className="intro-section__img">
                <img className='imgHome' src={imgHome} alt="imgHome" />
            </div>
            <div className="intro-text">
                <h1 className='intro-text__title'>
                    <span ref={typeTarget}></span>
                </h1>
                <p>
                    Passionné et rigoureux, j'ai débuté ma carrière en tant que coach sportif avant de me reconvertir en développeur web. J'ai trouvé dans ce nouveau domaine une opportunité passionnante de m'exprimer et de créer. En tant que développeur web, je suis naturellement enjoué et j'apporte une bonne humeur au sein de l'entreprise. J'apprécie les possibilités infinies offertes par ce domaine et je suis motivé à travailler sur divers projets.
                </p>
                <p>
                    Mon parcours m'a enseigné l'autonomie et la débrouillardise, des compétences que je mets en pratique pour mener mes projets avec détermination. Ma positivité, mon dynamisme et mon sourire font partie intégrante de ma façon d'aborder chaque défi avec enthousiasme.
                </p>
                <p>
                    J'espère que vous apprécierez explorer mon univers et découvrir les projets sur lesquels j'ai travaillé. N'hésitez pas à me contacter pour discuter de collaborations futures ou simplement pour échanger des idées. Merci de votre visite !
                </p>
            </div>

            <div className="container-cv">
                <a className="cv" href="/CV_RV_Dev.pdf" download="CV_RV_Dev.pdf">Télécharger mon CV</a>
            </div>

        </section>
    )
}

export default Accueil