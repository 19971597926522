import React from 'react';
import './Contact.scss';
import arrowUp from '../../assets/images/arrow-up-white.png';
import fbLogo from '../../assets/images/logo-fb-white.png';
import linkedinLogo from '../../assets/images/logo-linkedin-white.png';
import githubLogo from '../../assets/images/logo-github-white.png';
import instagramLogo from '../../assets/images/logo-instagram-white.png';

const Contact = () => {
    return (
        <section id='contact' className="contact-section">

            <div className="social-links">
                <a href="https://www.linkedin.com/in/valentin-ribeiro-lk" target="_blank" rel="noopener noreferrer">
                    <img src={linkedinLogo} alt="linkedin logo" className="logo" />
                </a>
                <a href="https://github.com/valentin705" target="_blank" rel="noopener noreferrer">
                    <img src={githubLogo} alt="github logo" className="logo" />
                </a>
                <a href="https://www.instagram.com/_valentin_ribeiro_/" target="_blank" rel="noopener noreferrer">
                    <img src={instagramLogo} alt="instagram logo" className="logo" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100002516781776" target="_blank" rel="noopener noreferrer">
                    <img src={fbLogo} alt="facebook logo" className="logo" />
                </a>
                <div className="scroll-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <img src={arrowUp} alt='arrow up' className='arrow-up' />
                </div>
            </div>

            <div className='contact-section__flex'>
                <div className='flex'>
                    <p className='name'>Valentin Ribeiro</p>
                    <p className='pro'>Développeur web</p>
                </div>
                <p className='copy'>© NS. All rights reserved</p>
            </div>

        </section>
    );
}

export default Contact;