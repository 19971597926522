import React from 'react';
import TechnologyCard from './TechnologyCard';
import './SectionTechnologies.scss';
import TechnologiesData from '../../data/TechnologiesData'; 
import { useState } from 'react';

function SectionTechnologies() {

    const [selectedGroup, setSelectedGroup] = useState('languageFramework');

    return (
        <section id="technologies" className="technologies">
            <h1 className="technologies__title">Technologies</h1>
            <p className="technologies__intro">
            Au cours de mes stages, j'ai participé à plusieurs projets dont certains ne sont pas disponibles ici en raison de contraintes de confidentialité. De plus, d'autres projets sont actuellement en cours de développement. Ma passion pour la technologie et mon désir constant d'apprentissage me pousseront à ajouter régulièrement de nouveaux travaux à ce portfolio. Merci de votre intérêt pour mon parcours.            </p>
            <div className="technologies__buttons">
                <button 
                    onClick={() => setSelectedGroup('languageFramework')}
                    className={selectedGroup === 'languageFramework' ? 'active' : ''}
                >
                    Langage et Framework
                </button>
                <button 
                    onClick={() => setSelectedGroup('designProjectManagement')}
                    className={selectedGroup === 'designProjectManagement' ? 'active' : ''}
                >
                    Design et Gestion de Projet
                </button>
            </div>
            <div className="technologies__flex">
                {TechnologiesData[selectedGroup].map((tech) => (  // Aucune erreur ici
                    <TechnologyCard technology={tech} key={tech.name} />
                ))}
            </div>
        </section>
    );
}

export default SectionTechnologies;

