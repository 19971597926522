import React from "react";
import "./AboutMe.scss";
// import imgNaya from "../../assets/images/NayaNoBack.png";
// import imgAiko from "../../assets/images/AikoNoBack.png";
import imgTriat from "../../assets/images/triat.png";
import imgAikoPaddle from "../../assets/images/aikoPaddle.jpg";
import imgAikoRun from "../../assets/images/aikoRun.jpg";
import imgNaya from "../../assets/images/nayaAboutMe.jpg";


function AboutMe() {
  return (
    <section id="about-me" className="about-me">
      <h2 className="about-me__title">À propos de moi</h2>
      <div className="about-me__content">
        <div className="about-me__intro">
          <h3 className="about-me__subtitle">Atouts</h3>
          <ul>
            <li>
              <strong className="about-me_atouts">Adaptabilité:</strong> Face aux différentes situations / aux changements (procédés/connaissances..)
            </li>
            <li>
              <strong>Dynamique/Enjoué:</strong> Energie et sourire au quotidien
            </li>
            <li>
              <strong>Rigoureux/Motivé:</strong> Persévérant, je donne toujours le meilleur de moi-même
            </li>
            <li>
              <strong>Esprit d'équipe/relationnel:</strong> J'aime partager, échanger et apprendre
            </li>
          </ul>
        </div>
        <div className="about-me__qualities" style={{ backgroundImage: `url(${imgTriat})` }}>
          <h3 className="about-me__subtitle">Sport</h3>
          <p>Passionné de sport, je pratique régulièrement la course à pieds, le vélo et la natation avec l'objectif de faire du triathlon avec une base de PPG et depuis peu de la plongée sous-marine.</p>
        </div>
        <div className="about-me__loisirs">
          <div className="about-me__loisirs-content">
            {/* <img src={imgNaya} alt="Naya" className="dog-left" /> */}
            <div className="about-me__loisirs-text">
              <h3 className="about-me__subtitle">Loisirs</h3>
              <p>J'aime me promener, voir mes amis, jouer aux jeux vidéos, regarder des séries/films. J'en profite pour vous présenter Aiko et Naya qui partagent mon quotidien. Et certaines de mes activités sportives !</p>
            </div>
            {/* <img src={imgAiko} alt="Aiko" className="dog-right" /> */}
          </div>
          <div className="about-me__loisirs-activities">
            <img src={imgAikoPaddle} alt="Aiko Paddle" />
            <img src={imgNaya} alt="Naya Run" />
            <img src={imgAikoRun} alt="Aiko Run" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;