import React, { useEffect, useRef, useCallback } from "react";
import "./SectionEducationAndCareer.scss";
import { useSpring, animated } from 'react-spring';
import Trigger from 'react-scroll-trigger';
import imgSport1 from "../../assets/images/sportCyberJump.png";
import imgSport2 from "../../assets/images/sportCyberKettel.png";
import imgCode1 from "../../assets/images/code1.png";
import imgCode2 from "../../assets/images/code2.png";

function AnimatedCard(props) {
    const cardRef = useRef(null);
    const direction = props.reverse ? 100 : -100;
    const [animationProps, set] = useSpring(() => ({
        transform: `translateX(${direction}%)`,
        config: { duration: 2000 },
    }));

    const onEnter = useCallback(() => {
        set({ transform: 'translateX(0%)' });
    }, [set]);

    const isInViewport = (element) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    useEffect(() => {
        if (cardRef.current && isInViewport(cardRef.current)) {
            onEnter();
        }
    }, [onEnter]);

    return (
        <Trigger onEnter={onEnter}>
            <animated.div ref={cardRef} style={animationProps} className={`card ${props.reverse ? 'reverse' : ''}`}>
                {props.children}
            </animated.div>
        </Trigger>
    );
}

function SectionEducationAndCareer() {
    return (
        <section id="formations-experiences" className="education-and-career">

            <article className="education-and-career__section sport-section">
                <h1 className="education-and-career__title">Formations et Expériences</h1>
                <h2 className="education-and-career__subtitle">Sport</h2>
                <div className="education-and-career__grid">
                    <AnimatedCard className="card">
                        <img src={imgSport1} alt="Sport" className="card__image" />
                        <div className="card__content">
                            <h3 className="card__title">Expérience</h3>
                            <p className="card__description">
                                Coach sportif, commercial, vendeur d'articles de sport pendant près de 10 ans, j'ai accompagné, motivé et guidé de nombreuses personnes dans leur quête de santé et de bien-être par le sport. Ces expériences m'ont inculqué discipline, passion et esprit d'équipe.
                            </p>
                        </div>
                    </AnimatedCard>
                
                <AnimatedCard reverse className="card reverse">
                    <div className="card__content">
                        <h3 className="card__title">Formation</h3>
                        <p className="card__description">
                            <li>2018 - 2019 : Formation BPJEPS AGFF mention C et D </li>
                            <li>2017 - 2018 : Formation BPJEPS AGFF mention C et D </li>
                            <li>Pro instructeur Les Mills Bodypump - Bodyattack - Sprint - RPM</li>
                        </p>
                    </div>
                    <img src={imgSport2} alt="Développement" className="card__image" />
                </AnimatedCard>
                </div>
            </article>

            <article className="education-and-career__section dev-section">
                <h1 className="education-and-career__title sport">Formations et Expériences</h1>
                <h2 className="education-and-career__subtitle">Développement</h2>
                <div className="education-and-career__grid">
                    <AnimatedCard className="card">
                        <img src={imgCode1} alt="Sport" className="card__image" />
                        <div className="card__content">
                            <h3 className="card__title">Expérience</h3>
                            <p className="card__description">
                                Développeur passionné, j'ai travaillé sur différents projets en formation, en pratique personnelle et lors de mes stages, renforçant sans cesse ma polyvalence et ma quête d'innovation.
                            </p>
                        </div>
                    </AnimatedCard>
                    <AnimatedCard reverse className="card reverse">
                        <div className="card__content">
                            <h3 className="card__title">Formation</h3>
                            <p className="card__description">
                                <li>2023 - 2024 : Formation concepteur développeur d'application (en cours)</li>
                                <li>2022 - 2023 : Formation développeur web et web mobile </li>
                            </p>
                        </div>
                        <img src={imgCode2} alt="Développement" className="card__image" />
                    </AnimatedCard>
                </div>
            </article>

        </section>
    );
}

export default SectionEducationAndCareer;