import React from 'react';
// import logo from '../../assets/images/logo.png';
import "./NavBarDesktop.scss"

function NavBarDesktop() {

  const handleLinkClick = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      const sectionTop = section.offsetTop - 6 * 16;

      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    // <nav className="navbar-desktop">
    //     {/* <img className="logo-desktop" src={logo} alt="logo" /> */}
    //     <p className="logo-desktop"><span className='logo-desktop__happy'>Happy</span>VitaCode</p>
    //     <ul className="menu-desktop">
    //     <li>
    //         <a href="#" onClick={() => handleLinkClick('accueil')}>Accueil</a>
    //     </li>
    //       <li>
    //         <a onClick={() => handleLinkClick('formations-experiences')}>Formations et expériences</a>
    //       </li>
    //       <li>
    //         <a onClick={() => handleLinkClick('technologies')}>Technologies</a>
    //       </li>
    //       <li>
    //         <a onClick={() => handleLinkClick('projects')}>Projets</a>
    //       </li>
    //       <li>
    //         <a onClick={() => handleLinkClick('about-me')}>A propos de moi</a>
    //       </li>
    //       <li>
    //         <a onClick={() => handleLinkClick('contact')}>Contact</a>
    //       </li>
    //     </ul>
    // </nav>
    <nav className="navbar-desktop">
      {/* <img className="logo-desktop" src={logo} alt="logo" /> */}
      <p className="logo-desktop"><span className='logo-desktop__happy'>Happy</span>VitaCode</p>
      <ul className="menu-desktop">
        <li>
          <a href='#acceuil' onClick={(e) => { e.preventDefault(); handleLinkClick('accueil'); }}>Accueil</a>
        </li>
        <li>
          <a href='#formations-experiences' onClick={(e) => { e.preventDefault(); handleLinkClick('formations-experiences'); }}>Formations et expériences</a>
        </li>
        <li>
          <a href='#technologies' onClick={(e) => { e.preventDefault(); handleLinkClick('technologies'); }}>Technologies</a>
        </li>
        <li>
          <a href='#projects' onClick={(e) => { e.preventDefault(); handleLinkClick('projects'); }}>Projets</a>
        </li>
        <li>
          <a href='#about-me' onClick={(e) => { e.preventDefault(); handleLinkClick('about-me'); }}>A propos de moi</a>
        </li>
        <li>
          <a href='#contact' onClick={(e) => { e.preventDefault(); handleLinkClick('contact'); }}>Contact</a>
        </li>
      </ul>
    </nav>

  );
}

export default NavBarDesktop;
