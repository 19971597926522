import htmlLogo from '../assets/images/html-logo.png';
import cssLogo from '../assets/images/css-logo.png';
import jsLogo from '../assets/images/js-logo.png';
import reactLogo from '../assets/images/react-logo.png';
import vueLogo from '../assets/images/vue-logo.png';
import phpLogo from '../assets/images/php-logo.png';
import symfonyLogo from '../assets/images/symfony-logo.png';
import bootstrapLogo from '../assets/images/bootstrap-logo.png';
import jqueryLogo from '../assets/images/jquery-logo.png';
import figmaLogo from '../assets/images/figma-logo.png';
import githubLogo from '../assets/images/github-logo.png';
import sassLogo from '../assets/images/sass-logo.png';
import canvaLogo from '../assets/images/canva-logo.png';
import trelloLogo from '../assets/images/trello-logo.png';
// import clickupLogo from '../assets/images/clickup-logo.png';

const githubURL = 'https://github.com/Valentin705';
const vueProject1 = 'https://github.com/valentin705/projet_vue3.js';
const vueProject2 = 'https://github.com/valentin705/app_vue3_build_a_robot';
const symfonyProject1 = 'https://github.com/valentin705/app_sport_pfl';
const symfonyProject2 = 'https://github.com/valentin705/app_gestion_evenements';
const phpProject1 = 'https://github.com/valentin705/projet_banque';
const fondasolProject = 'https://github.com/valentin705/projet_integration';

const TechnologiesData = {
    languageFramework: [
        {
            name: 'HTML',
            logo: htmlLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <a className='data-link' href={githubURL} target="_blank" rel="noopener noreferrer">Tous les projets</a>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris en formation</p>
                </>
            ),
        },
        {
            name: 'CSS',
            logo: cssLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <a className='data-link' href={githubURL} target="_blank" rel="noopener noreferrer">Tous les projets</a>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris en formation</p>
                </>
            ),
        },
        {
            name: 'JavaScript',
            logo: jsLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <a className='data-link' href={githubURL} target="_blank" rel="noopener noreferrer">Tous les projets</a>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris en formation</p>
                </>
            ),
        },
        {
            name: 'React',
            logo: reactLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <p className='data-link'>Mon portfolio</p>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris de manière autonome</p>
                </>
            ),
        },
        {
            name: 'Vue',
            logo: vueLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <a className='data-link' href={vueProject1} target="_blank" rel="noopener noreferrer">Intégration Vue3</a><br />
                    <a className='data-link' href={vueProject2} target="_blank" rel="noopener noreferrer">Build A Robot</a>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris de manière autonome</p>
                </>
            ),
        },
        {
            name: 'PHP',
            logo: phpLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <a className='data-link' href={phpProject1} target="_blank" rel="noopener noreferrer">Projet Banque</a>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris en formation</p>
                </>
            ),
        },
        {
            name: 'Symfony',
            logo: symfonyLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <a className='data-link' href={symfonyProject1} target="_blank" rel="noopener noreferrer">Reseau social SportNet</a><br />
                    <a className='data-link' href={symfonyProject2} target="_blank" rel="noopener noreferrer">Symfony Events</a>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris de manière autonome</p>
                </>
            ),
        },
        {
            name: 'Bootstrap',
            logo: bootstrapLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <a className='data-link' href={symfonyProject1} target="_blank" rel="noopener noreferrer">Reseau social SportNet</a><br />
                    <a className='data-link' href={symfonyProject2} target="_blank" rel="noopener noreferrer">Symfony Events</a>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris en formation</p>
                </>
            ),
        },
        {
            name: 'jQuery',
            logo: jqueryLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <p className='data-link'>Au cours de mon stage (confidentiel)</p>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris de manière autonome</p>
                </>
            ),
        },
        {
            name: 'Sass',
            logo: sassLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <p className='data-link'>Mon portfolio</p>
                    <a className='data-link' href={vueProject1} target="_blank" rel="noopener noreferrer">Intégration Vue3</a><br />
                    <a className='data-link' href={fondasolProject} target="_blank" rel="noopener noreferrer">Integration Fondasol</a>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris de manière autonome</p>
                </>
            ),
        },

    ],
    designProjectManagement: [
        {
            name: 'Figma',
            logo: figmaLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <p className='data-link'>SportNet</p>
                    <p className='data-link'>Circle product</p>
                    <p className='data-link'>Au cours de mon stage (confidentiel)</p>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris de manière autonome</p>
                </>
            ),
        },
        {
            name: 'Git',
            logo: githubLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <a className='data-link' href={githubURL} target="_blank" rel="noopener noreferrer">Tous les projets</a>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris en formation</p>
                </>
            ),
        },
        {
            name: 'Canva',
            logo: canvaLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <p className='data-link'>Utilisation régulière pour divers projets</p>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris de manière autonome</p>
                </>
            ),
        },
        {
            name: 'Trello',
            logo: trelloLogo,
            projets: (
                <>
                    <p className='data-project'>Projets :</p>
                    <p className='data-link'>SportNet</p>
                </>
            ),
            apprentissage: (
                <>
                    <p className='data-learning'>Appris de manière autonome</p>
                </>
            ),
        },
    ],
};


export default TechnologiesData;