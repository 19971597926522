import React, { useState } from "react";

function TechnologyCard({ technology }) {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleCardClick = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <div
            className="technologyCard"
            onClick={handleCardClick}
        >
            <div className={`technology-inner ${isFlipped ? 'flipped' : ''}`}>
                <div className="technology-front">
                    <img src={technology.logo} alt={technology.name} className="technology-logo" />
                    <div className="technology-name">{technology.name}</div>
                </div>
                <div className="technology-back">
                    <div className="technology-description">
                        {technology.projets}
                        {technology.apprentissage}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TechnologyCard;
