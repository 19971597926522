import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./SectionProjets.scss"; 
import project1 from "../../assets/images/sportnet.png";
import project2 from "../../assets/images/symfonyevent.png";
import project3 from "../../assets/images/fondasolintegration.png";
import project4 from "../../assets/images/circleproduct.png";
import project5 from "../../assets/images/buildarobot.png";

const projects = [
    {
        image: project1,
        title: "SportNet",
        link: "https://github.com/valentin705/app_sport_pfl",
    },
    {
        image: project2,
        title: "Symfony Events",
        link: "https://github.com/valentin705/app_gestion_evenements",
    },
    {
        image: project3,
        title: "Fondasol Integration",
        link: "https://github.com/valentin705/projet_integration",
    },
    {
        image: project4,
        title: "Circle Product",
        link: "https://github.com/valentin705/projet_vue3.js",
    },
    {
        image: project5,
        title: "Build a Robot",
        link: "https://github.com/valentin705/app_vue3_build_a_robot",
    },
  ];

function SectionProjets() {
  return (
    <section id="projects" className="projets">
    <h1 className="projets__title">Projets</h1>
    <div className="carousel-container">
      <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={3000}
      >
          {projects.map((project) => (
              <div className="carousel" key={project.title}>
                  {project.link ? (
                      <a href={project.link} target="_blank" rel="noopener noreferrer">
                          <img src={project.image} alt={project.title} />
                          <h3 className="carousel__title">{project.title}</h3>
                      </a>
                  ) : (
                      <>
                          <img src={project.image} alt={project.title} />
                          <h3 className="carousel__title">{project.title}</h3>
                      </>
                  )}
              </div>
          ))}
      </Carousel>
    </div>
  </section>
  );
}

export default SectionProjets;

// import React, { useState } from "react";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "./SectionProjets.scss"; 
// import project1 from "../../assets/images/code1.png";
// import project2 from "../../assets/images/code2.png";
// import project3 from "../../assets/images/code3.png";
// import SportNetModal from "../modals/SportNetModal";

// const projects = [
//     {
//         image: project1,
//         title: "SportNet",
//         link: "https://github.com/valentin705/app_sport_pfl",
//     },
//     {
//         image: project2,
//         title: "Symfony Events",
//         link: "https://github.com/valentin705/app_gestion_evenements",
//     },
//     {
//         image: project3,
//         title: "Projet 3",
//     },
// ];

// function SectionProjets() {

//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [selectedModal, setSelectedModal] = useState(null);

//     const handleImageClick = (project) => {
//         if (project.title === "SportNet") {
//             setSelectedModal(project.title);
//             setIsModalOpen(true);
//         }
//     };

//     const closeModal = () => {
//         setIsModalOpen(false);
//         setSelectedModal(null);
//     };

//     return (
//         <section id="projects" className="projets">
//             <h1 className="projets__title">Projets</h1>
//             <div className="carousel-container">
//                 <Carousel
//                     showArrows={true}
//                     infiniteLoop={true}
//                     showThumbs={false}
//                     showStatus={false}
//                     autoPlay={true}
//                     interval={3000}
//                 >
//                     {projects.map((project) => (
//                         <div className="carousel" key={project.title}>
//                             <img 
//                                 src={project.image} 
//                                 alt={project.title} 
//                                 onClick={() => handleImageClick(project)}
//                             />
//                             {project.link ? (
//                                 <a href={project.link} target="_blank" rel="noopener noreferrer">
//                                     <h3 className="carousel__title">{project.title}</h3>
//                                 </a>
//                             ) : (
//                                 <h3 className="carousel__title">{project.title}</h3>
//                             )}
//                         </div>
//                     ))}
//                 </Carousel>
//             </div>
//             <SportNetModal isOpen={isModalOpen && selectedModal === 'SportNet'} onRequestClose={closeModal} />
//         </section>
//     );
// }

// export default SectionProjets;
